<template>
  <section class="container-fluid mt-4">
    <div class="d-flex justify-content-between">
      <h5 class="font-weight-bold text-left d-flex align-items-baseline">
        แผนปฏิบัติราชการด้านพลังงานของจังหวัด
        <div
          class="text-gray text-medium pointer ml-3"
          v-tooltip="{
            content:
              'กราฟแสดงความคืบหน้าของเนื้อหาแต่ละหัวข้อของร่างแผนฯ<br>ข้อเขียน “บทสรุปสำหรับผู้บริหาร”<br>ข้อเขียน “ภาคผนวก” รายการเอกสารอ้างอิงที่เกี่ยวข้อง',
            html: true,
          }"
        >
          <u>ข้อมูลเพิ่มเติม</u>
        </div>
      </h5>
      <div>
        <router-link
          :to="{ name: 'peno.appendix' }"
          class="btn btn-outline-red py-1 px-4 mr-3"
        >
          ไปต่อ (ภาคผนวก)
          <eva-icon name="arrow-ios-forward" fill="currentColor"></eva-icon>
        </router-link>
        <router-link
          :to="{ name: 'peno.preview' }"
          class="btn btn-outline-red py-1 px-4 mr-3"
        >ดูร่างแผน ฯ
        </router-link>
        <!--<button class="btn btn-action py-1 px-4" @click="submitReport">-->
        <!--ส่งแผน-->
        <!--</button>-->
      </div>
    </div>
    <div class="row mt-4" v-if="ready">
      <div class="col-12 col-md-4">
        <div class="box">
          <div class="p-3 pb-2 border-bottom border-gray">
            <div class="text-light-ipepp font-weight-bold">
              สถานะของแผนปฏิบัติราชการ
            </div>
            <div
              class="d-flex justify-content-between mt-3 align-items-baseline"
            >
              <div class="">กำหนดส่งแผนจะครบกำหนดภายใน</div>
              <div
                v-if="reportStatus.remaining_days > 0"
                class="font-weight-bold text-h5"
                :class="{
                  'text-yellow':
                    reportStatus.remaining_days < 5 &&
                    reportStatus.remaining_days >= 0,
                }"
              >
                {{ reportStatus.remaining_days }} วัน
              </div>
              <div v-else-if="reportStatus.remaining_days < 0" class="text-secondary text-right">
                <div class="font-weight-bold text-danger">เลยกำหนดส่ง</div>
                <small class="mt-2">(กำหนดส่ง {{ reportStatus.end_date | thai_date }})</small>
              </div>
              <div v-else class="font-weight-bold text-h5 text-danger">
                วันนี้
              </div>
            </div>
          </div>
          <div class="p-3 pb-2 border-bottom border-gray">
            <div class="d-flex justify-content-between">
              <div>สร้างขึ้นเมื่อ</div>
              <div class="helper-color">
                {{ reportStatus.created_at | thai_date }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>แก้ไขล่าสุดเมื่อ</div>
              <div class="helper-color">
                {{ reportStatus.updated_at | thai_date }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>สถานะ</div>
              <div class="font-weight-bold">
                {{ reportStatus.version | report_status }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="box">
          <div class="box-header">
            <div class="text-ipepp font-weight-bold">
              แผนปฏิบัติราชการด้านพลังงานของจังหวัด{{
                currentProvince | province_name_locale
              }}
              ปี พ.ศ.
              {{ latestNationalPlan.year | thai_year }}
            </div>
          </div>
          <div class="box-body pb-5">
            <div
              class="d-flex"
              v-for="(topic, index) in reportStatus.topics"
              :key="index"
              v-bind:class="{ 'mt-3': !(index == 0) }"
            >
              <div class="pb-3 pr-3 pl-3 font-weight-bold">
                {{ parseInt(index) + 1 }}
              </div>
              <div class="flex-grow-1 d-flex pb-3 border-bottom border-gray">
                <div class="w-75">
                  {{ topic.title }}
                </div>
                <div class="w-25">
                  <report-progress-bar :progress="topic.progress">
                  </report-progress-bar>
                </div>
                <div v-show="allowEdit">
                  <div
                    v-if="index == 0"
                    class="ml-3 text-a-style text-underline"
                    @click="scrollToExecutiveSummary"
                  >
                    แก้ไข
                  </div>
                  <router-link
                    v-else
                    class="ml-3 text-underline"
                    :to="{ name: routeNameToEdit(topic.type) }"
                  >
                    แก้ไข
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box mt-4" ref="executive_summary">
          <div class="box-header no-border d-flex justify-content-between px-5">
            <!--<div class="text-ipepp font-weight-bold">-->
            <!--จังหวัด{{ currentProvince | province_name_locale }}-->
            <!--</div>-->
            <div class="text-ipepp font-weight-bold">บทสรุปสำหรับผู้บริหาร</div>
            <!--<div class="text-ipepp font-weight-bold">-->
            <!--ปี {{ latestNationalPlan.year | thai_year }}-->
            <!--</div>-->
          </div>
          <div class="box-body pb-5">
            <div class="">
              <ul>
                <li class="mb-2">
                  <div class="">
                    โปรดสรุปสาระสำคัญของความจำเป็นที่ต้องมีแผนปฏิบัติราชการจังหวัด
                    <span
                      class="pointer text-blue"
                      @click="showGuidingModal('cause')"
                    >
                      <u>ดูคำแนะนำ</u>
                    </span>
                  </div>
                </li>
                <li class="mb-2">
                  <div class="">
                    โปรดสรุปสาระสำคัญของกระบวนการจัดทำแผนปฏิบัติราชการ
                    และภาคส่วนที่ได้มีส่วนร่วม
                    <span
                      class="pointer text-blue"
                      @click="showGuidingModal('process_and_participant')"
                    >
                      <u>ดูคำแนะนำ</u>
                    </span>
                  </div>
                </li>
                <li class="mb-2">
                  <div class="">
                    โปรดระบุเป้าประสงค์ด้านพลังงานสำหรับจังหวัด
                    <span
                      class="pointer text-blue"
                      @click="showGuidingModal('target_summary')"
                    >
                      <u>ดูคำแนะนำ</u>
                    </span>
                  </div>
                </li>
                <li class="mb-2">
                  <div class="">
                    โปรดสรุปสาระสำคัญของแผนปฏิบัติราชการด้านพลังงานของจังหวัด
                    <span
                      class="pointer text-blue"
                      @click="showGuidingModal('primary_summary')"
                    >
                      <u>ดูคำแนะนำ</u>
                    </span>
                  </div>
                </li>
                <li class="mb-2">
                  <div class="">
                    โปรดสรุปสาระสำคัญของผลสัมฤทธิ์ที่คาดหวังจากแผนปฏิบัติราชการ
                    <span
                      class="pointer text-blue"
                      @click="showGuidingModal('expected_result')"
                    >
                      <u>ดูคำแนะนำ</u>
                    </span>
                  </div>
                </li>
              </ul>
              <div v-if="isDirty('cause')" class="text-danger">
                (ยังไม่ได้บันทึก)
              </div>
              <trumbowyg
                ref="editor"
                v-model="credentials.cause"
                :config="config"
                class=""
                placeholder="รายละเอียด"
              ></trumbowyg>
              <div
                class="error text-danger mb-3"
                v-if="credentials.$v.cause.$dirty"
              >
                <div v-if="!credentials.$v.cause.required">โปรดระบุ</div>
                <div v-if="!credentials.$v.cause.maxLength">
                  เกินจำนวนตัวอักษรที่กำหนด
                </div>
              </div>
            </div>
            <div class="text-right">
              <button
                v-promise-btn
                class="btn btn-action py-1 px-4"
                @click="save"
                v-show="allowEdit"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ReportProgressBar from '@/components/ReportProgressBar.vue';
import Form from '../../../modules/form/form';
import {maxLength, required} from 'vuelidate/lib/validators';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingCause from '@/components/guiding/executive_summary/GuidingCause.vue';
import GuidingProcessAndParticipant from '@/components/guiding/executive_summary/GuidingProcessAndParticipant.vue';
import GuidingTargetSummary from '@/components/guiding/executive_summary/GuidingTargetSummary.vue';
import GuidingPrimarySummary from '@/components/guiding/executive_summary/GuidingPrimarySummary.vue';
import GuidingExpectedResult from '@/components/guiding/executive_summary/GuidingExpectedResult.vue';
import {trim} from 'lodash';

export default {
  name: 'PenoReportSummary',

  components: {
    ReportProgressBar,
  },

  data() {
    return {
      reportStatus: null,
      ready: false,
      config: {
        semantic: false,
        btns: [
          ['fontsize'],
          ['fontfamily'],
          ['underline', 'italic', 'bold'],
          ['fullscreen'],
        ],
        plugins: {
          smaller: {},
          fontfamily: {
            fontList: this.$fontfamilyList,
          },
        },
      },
      credentials: new Form({
        data: () => {
          return {
            cause: '',
            process_and_participant: '',
            target_summary: '',
            primary_summary: '',
            expected_result: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          cause: {
            required,
            maxLength: maxLength(16000000),
          },
          // process_and_participant: {
          //   required,
          //   maxLength: maxLength(16000000)
          // },
          // target_summary: {
          //   required,
          //   maxLength: maxLength(16000000)
          // },
          // primary_summary: {
          //   required,
          //   maxLength: maxLength(16000000)
          // },
          // expected_result: {
          //   required,
          //   maxLength: maxLength(16000000)
          // }
        },
      }),
    };
  },

  created() {
    this.init();
  },

  mounted() {
    window.onbeforeunload = () => {
      if (this.credentials.isDirty()) {
        return 'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!';
      } else {
        return void 0;
      }
    };
  },

  beforeDestroy() {
    window.onbeforeunload = null;
  },

  computed: {
    ...mapState('peno', ['currentReport']),
    ...mapGetters('peno', ['currentProvince', 'allowEdit']),
    ...mapGetters(['latestNationalPlan']),
    executiveSummary() {
      return this.currentReport.executive_summary || [];
    },
  },

  methods: {
    ...mapActions('province', ['fetchProvinceReportStatus']),
    init() {
      this.credentials.cause = this.executiveSummary.cause;
      this.credentials.process_and_participant =
        this.executiveSummary.process_and_participant;
      this.credentials.target_summary = this.executiveSummary.target_summary;
      this.credentials.primary_summary = this.executiveSummary.primary_summary;
      this.credentials.expected_result = this.executiveSummary.expected_result;
      this.credentials.cleanDirty();
      this.fetchProvinceReportStatus({
        nationalPlan: this.latestNationalPlan,
        province: this.currentProvince,
      }).then((data) => {
        this.reportStatus = data;
        this.ready = true;
      });
    },
    routeNameToEdit(type) {
      switch (type) {
        case 1:
          return '';
        case 2:
          return 'peno.current_situation';
        case 3:
          return 'peno.targets';
        case 4:
          return 'peno.swot';
        case 5:
          return 'peno.swot';
        case 6:
          return 'peno.projects';
        case 7:
          return 'peno.plan_relation';
        default:
          return '';
      }
    },
    scrollToExecutiveSummary() {
      this.$scrollTo(this.$refs.executive_summary);
    },
    isDirty(field) {
      return (
        trim(this.credentials[field]) != trim(this.executiveSummary[field])
      );
    },
    showGuidingModal(field) {
      var comp = (function (field) {
        switch (field) {
          case 'cause':
            return GuidingCause;
          case 'process_and_participant':
            return GuidingProcessAndParticipant;
          case 'target_summary':
            return GuidingTargetSummary;
          case 'primary_summary':
            return GuidingPrimarySummary;
          case 'expected_result':
            return GuidingExpectedResult;
          default:
            return GuidingCause;
        }
      })(field);

      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: comp,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    save() {
      return this.credentials
        .patch('/api/current_report/executive_summary/update')
        .then((data) => {
          this.$store.commit('peno/setExecutiveSummary', data);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    // moved to report preview page
    // submitReport() {
    //   window.axios
    //     .post('/api/current_report/submit')
    //     .then(response => {
    //       this.$router.push({
    //         name: 'peno.overview.intro'
    //       });
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }
  },
  beforeRouteLeave(to, from, next) {
    if (this.credentials.isDirty()) {
      const answer = window.confirm(
        'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!'
      );
      if (answer) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped></style>
